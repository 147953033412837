<template>
  <div>
    <h1>Forgot Password</h1>
    <b-card border-variant="light" bg-variant="light" class="text-center">
      <b-form @submit="submit" @reset="onReset" id="forgot-pwd-form">
        <b-card-body>
          <b-alert :show="error !== ''" variant="danger">{{ error }}</b-alert>
          <b-alert :show="message !== ''" variant="warning">{{
            message
          }}</b-alert>
          <b-row class="my-2">
            <b-col md="1" offset-md="4">
              <label for="input-live">Email: </label>
            </b-col>
            <b-col md="4">
              <b-form-input
                id="email-live"
                v-model="email"
                :state="emailRule"
                type="email"
                aria-required="true"
                placeholder="Enter Email id"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="email-live">
                Please enter Email Id
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <br />
          <div>
            <a href="#" @click="getUserName">Forgot Username? Click here</a>
          </div>
          <div class="my-4">
            <b-button type="submit" variant="primary" class="mx-4"
              >Submit</b-button
            >
            <b-button type="reset" variant="danger" class="mx-4"
              >Cancel</b-button
            >
          </div>
        </b-card-body>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  BAlert,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  name: "ForgotPassword",

  metaInfo: {
    title: "Nisharga",
    titleTemplate: "%s | Forgot Password | Your way to the world of Nirvana",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Have you lost your key to the world of nirvana? Enter and Email to recover you password now! Get unlimited content of free Stories | Articles | Blogs | Poems and much more. Its free, easy and enjoyable.",
      },
      {
        name: "og:description",
        content:
          "Have you lost your key to the world of nirvana? Enter and Email to recover you password now! Get unlimited content of free Stories | Articles | Blogs | Poems and much more. Its free, easy and enjoyable.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  components: {
    BAlert,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
  },

  data() {
    return {
      email: "",
      error: "",
      message: "",
      emailRule: true,
    };
  },

  methods: {
    ...mapActions(["forgotPassword", "forgotUserName"]),

    onReset() {
      this.email = "";
      this.message = "";
      this.error = "";
      this.$router.push("/login");
    },

    async getUserName() {
      this.error = "";
      if (!this.email) {
        this.emailRule = false;
        return;
      }
      try {
        this.emailRule = true;
        await this.forgotUserName({ email: this.email });
        this.message = `You Username has been sent to ${this.email}`;
      } catch (e) {
        console.error(e);
        this.error = e.response.data.message;
      }
    },

    async submit(event) {
      event.preventDefault();
      try {
        this.emailRule = true;
        await this.forgotPassword({ email: this.email });

        this.message = `Link to reset password has been sent to ${this.email}`;
      } catch (e) {
        console.error(e);
        this.error = e.response.data.message;
      }
    },
  },
};
</script>
